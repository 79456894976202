import request from '@/utils/request'


// 查询用户注销类型列表
export function listUserLogoutType(query) {
  return request({
    url: '/platform/user-logout-type/list',
    method: 'get',
    params: query
  })
}

// 查询用户注销类型分页
export function pageUserLogoutType(query) {
  return request({
    url: '/platform/user-logout-type/page',
    method: 'get',
    params: query
  })
}

// 查询用户注销类型详细
export function getUserLogoutType(data) {
  return request({
    url: '/platform/user-logout-type/detail',
    method: 'get',
    params: data
  })
}

// 新增用户注销类型
export function addUserLogoutType(data) {
  return request({
    url: '/platform/user-logout-type/add',
    method: 'post',
    data: data
  })
}

// 修改用户注销类型
export function updateUserLogoutType(data) {
  return request({
    url: '/platform/user-logout-type/edit',
    method: 'post',
    data: data
  })
}

// 删除用户注销类型
export function delUserLogoutType(data) {
  return request({
    url: '/platform/user-logout-type/delete',
    method: 'post',
    data: data
  })
}
